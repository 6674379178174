import config from '../config'
import {
  FETCH_STATIC_DATA_SUCCESS,
  FETCH_STATIC_DATA_FAILED,
} from '../actions/app-actions'

let initialState = {
  ...config.resume,
}
const resumeReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATIC_DATA_SUCCESS:
      return {
        ...state,
        resume: {
          ...action.data.resume,
        },
      }
    case FETCH_STATIC_DATA_FAILED:
    default:
      return state
  }
}
export default resumeReducers
