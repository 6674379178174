import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class PDFViewer extends Component {
  static propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    downloadUrl: PropTypes.string,
    embedType: PropTypes.oneOf(['iframe', 'dropbox-embed']),
  }

  render() {
    let content
    switch (this.props.embedType) {
      // https://www.dropbox.com/developers/embedder
      case 'dropbox-embed':
        content = (
          <a
            aria-label={this.props.title}
            className="dropbox-embed"
            href={this.props.url}
            data-height="700px"
            data-width="100%"
            height="700px"
            width="100%"
          />
        )
        break
      case 'iframe':
      default:
        content = (
          <iframe
            title={this.props.title}
            src={this.props.url}
            style={{ width: '100%', height: '700px' }}
          />
        )
        break
    }
    return (
      <div className={'resume-pdf-iframe'} style={{ width: '100%' }}>
        <div>
          <a className="ml-40p mb-2p" href={this.props.downloadUrl}>
            Download this file
          </a>
          <br />
          {content}
        </div>
      </div>
    )
  }
}
